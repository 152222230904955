
import { defineComponent, onMounted, computed } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import VPagination from "@hennge/vue3-pagination";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import AppService from "@/core/services/AppService";
export default defineComponent({
  name: "role-list",
  components: { VPagination },
  props: {
    page: {
      type: String,
      default: "1",
    },
    limit: {
      type: String,
      default: "20",
    },
    name: {
      type: String,
      default: "",
    },
    order: {
      type: String,
      default: "desc",
    },
    order_by: {
      type: String,
      default: "name",
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    let params = {
      page: parseInt(props.page),
      limit: parseInt(props.limit),
      name: props.name,
      order: props.order,
      order_by: props.order_by,
    };
    const action_get_data = "GET_ROLES_DATA";
    const limitData = [5, 10, 20, 50, 100];
    store.dispatch(action_get_data, AppService.convertParams(params));
    const listData = computed(() => store.getters["listDataRoles"]);
    const totalPages = computed(() => store.getters["getRolesDocs"]);
    const totalDocs = computed(() => store.getters["getTotalRoles"]);
    const roleUser = computed(() => store.getters["getRoleUser"]);
    const onChangePage = async (next_page) => {
      params.page = next_page;
      await AppService.getDataListObject(
        params,
        store,
        router,
        action_get_data,
        "roles"
      );
    };
    const onChangeLimit = async (event) => {
      let positionMax =
        params.limit * params.page > totalDocs.value
          ? totalDocs.value
          : params.limit * params.page;
      params.limit = event.target.value;
      params.page = Math.ceil(positionMax / params.limit);
      await AppService.getDataListObject(
        params,
        store,
        router,
        action_get_data,
        "roles"
      );
    };
    const onClickSort = async (order_by) => {
      params.order_by = order_by;
      params.order = params.order === "desc" ? "asc" : "desc";
      await AppService.getDataListObject(
        params,
        store,
        router,
        action_get_data,
        "roles"
      );
    };
    const onSearch = async () => {
      await AppService.getDataListObject(
        params,
        store,
        router,
        action_get_data,
        "roles"
      );
    };
    const onClickDelete = (id) => {
      Swal.fire({
        text: "Bạn chắc chắn muốn xóa chứ ?",
        icon: "warning",
        buttonsStyling: false,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Xác nhận",
        cancelButtonText: "Hủy",
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-primary",
        },
      }).then(function (result) {
        let searchString = AppService.convertParams(params);
        if (result.isConfirmed) {
          store
            .dispatch("DELETE_ROLE", { id, searchString })
            .then(() => {
              Swal.fire({
                text: "Xóa thành công",
                icon: "success",
                buttonsStyling: false,
                showConfirmButton: false,
                timer: 1000,
                customClass: {
                  confirmButton: "btn fw-bold btn-light-success",
                  cancelButton: "btn fw-bold btn-light-primary",
                },
              });
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getRoleErrors,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Try again!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        }
      });
    };
    onMounted(() => {
      setCurrentPageBreadcrumbs("Nhóm Quyền người dùng", ["Người dùng"]);
      MenuComponent.reinitialization();
    });
    return {
      listData,
      params,
      limitData,
      totalDocs,
      totalPages,
      roleUser,
      onChangePage,
      onClickDelete,
      onChangeLimit,
      onClickSort,
      onSearch,
    };
  },
});
